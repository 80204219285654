body {
  margin: 0;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #42424200; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:#686868; 
  border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

/* .MuiDrawer-paperAnchorLeft{
  background-color: #1e1f21!important;
} */

.MuiDataGrid-toolbarContainer  .MuiButton-text{
  color: #000;
}

.pivot-column .MuiDataGrid-cell{
  background-color: #f3f3f3!important;
  color: #a3a3a3!important;
  
}

.MuiTabs-indicator {
  background-color: #000!important;
}


.e-switch-wrapper.bar-color .e-switch-inner.e-switch-active,
.e-switch-wrapper.bar-color:hover .e-switch-inner.e-switch-active .e-switch-on {
  background-color: #4d841d;
  border-color: #4d841d;
}

.e-switch-wrapper.bar-color .e-switch-inner,
.e-switch-wrapper.bar-color .e-switch-off {
  background-color: #e3165b;
  border-color: #e3165b;
}

.e-switch-wrapper.bar-color .e-switch-handle {
  background-color: #fff;
}

.bar-color {
  zoom: .7;
}

.iconAddAvatar{
  position: relative;
}

@media screen and (max-width: 600px){
  .mobile-100{
    width: 100%;
  }
}

/* .collapseMenu{
  transition: all .3s ease-in;
  opacity: 1;
} */

.super-app-theme--bg:nth-child(odd){
  background-color: #f7f7f7;
}

.odd-gray:nth-child(odd){
  background-color: #f7f7f7;
}

.css-17r1pi4-MuiDataGrid-root .MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-hovered{
  background-color: #0072ff12!important;
}

@media screen and (min-width: 900px){
  .desktop-100{
    width: unset!important;
  }
}

.settings--tabpanel{
  height: 100%;
}

.settings--tabpanel .MuiBox-root{
  height: calc(100% - 17px);
} 

@media screen and (max-width: 870px){
  .prodHunter-rangeList-settings{
    display: flex;
    flex-direction: column;
    align-items: flex-start!important;
    padding-right: 0!important;
  }
}

.dzu-dropzone label{
    color: #303030;
}

.dzu-dropzone{
  border-style: dashed!important;
}

.dzu-submitButton{
  height: 50px;
  padding: 0 40px!important;
  background-color: #313234!important;
  color: #fff;
  font-weight: unset!important;
  border-radius: 10px;
}
.dzu-submitButton{
  height: 50px;
  background-color: #000!important;
  color: #fff;
}

.fixed_headers {
  table-layout: fixed;
  border-collapse: collapse;
}

.logo-img-op{
  opacity: 1;
  transition: opacity .2s ease-in-out;
}

.PD-cellColor{
  background-color: #dcffff34;
}

.AD-cellColor{
  background-color: #cfcfcf34;
}

.dzu-submitButtonContainer .dzu-submitButton:disabled, .MuiButton-root:disabled{
  color: #30303034!important;
  background-color: #f4f4f4!important;
}


@media screen and (max-width: 767px){
  .prodWarehouseBox{
    flex-direction: column!important;
  }
}

.moreOptionsSearch label span{
  /* padding: 5px; */
}

.moreOptionsSearch label span:not(:first-child){
  padding: 5px;
  
}


.moreOptionsSearch span{
  font-size: .75rem;
  color: #474747;
}

@media screen and (min-width: 600px){
  .toolbar-width{
    width: calc(100% - 240px);
    }
}


.expandMore-button{
  padding: 0; 
  margin: 0px 5px;
  cursor: pointer;
  display: flex;
  font-size: .8rem
}
.expandMore-button svg{
  font-size: 1.2rem!important
}

.newChange {
  background-color: #dbf7d5;
  color: #000;
  font-weight: 500;

}

.fine {
  color: #000;
}

.danger{
  background-color: #ffadad;
  color: #000;
  font-weight: 500;
};

body {
  background: #0e101c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  color: white;
  padding: 0 20px 100px;
}

.h1 {
  margin-top: 80px;
  color: white;
  font-size: 25px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}

.form {
  max-width: 800px;
  margin: 0 auto;
}

.p {
  color: #bf1650;
  text-align: center;
}

input {
  transition: 0.3s all;
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

.label,
section > label {
  line-height: 2;
  text-align: left;
  display: block;
  margin-bottom: 13px;
  margin-top: 20px;
  color: white;
  font-size: 14px;
  font-weight: 200;
}

input[type="submit"],
.button {
  background: #ec5990;
  color: white;
  text-transform: uppercase;
  border: none;
  margin-top: 40px;
  padding: 20px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 10px;
  display: block;
  appearance: none;
  border-radius: 4px;
  width: 100%;
}

.buttonBlack {
  background: black;
  border: 1px solid white;
}

.App {
  max-width: 600px;
  margin: 0 auto;
}

.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}

.counter {
  font-weight: 400;
  background: white;
  color: black;
  padding: 10px 15px;
  border-radius: 4px;
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999999;
  border: 1px solid rgb(79, 98, 148);
  box-shadow: 0 0 4px rgb(79, 98, 148);
}




.section {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.error {
  border-left: 5px solid#ec5990;
}
